body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiSelect-icon {
  top: calc(50% - 15px) !important;
}
.MuiFormHelperText-root.Mui-error {
  color: #f15b55;
  margin: 0;
  font-size: 13px;
}

.MuiFormControlLabel-root > .MuiFormControlLabel-label {
  display: flex;
}

.link-with-dropdown {
  position: relative;
}

.link-with-dropdown > a {
  font: normal normal 500 16px/21px Roboto;
  color: #000000;
  letter-spacing: 0px;
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  img {
    vertical-align: bottom;
    margin: 0 5px;
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 240px;
  background-color: #fff;
  padding: 5px;

  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 99999;
}

.selectInTable {
  height: 40px;
  padding: 0 10px;
}

.cell-truncate {
  min-width: 200px;
}
.cell-truncate span {
  min-width: 200px;
  display: block;
}
