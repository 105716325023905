body {
  margin: 0;
  padding: 0;
  background-color: #ececec !important;
  font-size: 16px;
}
.MuiInputBase-formControl,
.MuiOutlinedInput-root {
  color: black !important;
  border-radius: 0 !important;
  outline-color: black !important;
}
.MuiFormLabel-root.Mui-focused {
  color: black !important;
  border-color: black !important;
}
.MuiPaper-elevation4 {
  box-shadow: none;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.Toastify__toast-body {
  font-size: 1rem;
}

.MuiFormGroup-root {
  flex-wrap: nowrap !important;
  flex-direction: row !important;
}

/*Datepicker css fixes*/

.datepicker-range-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}

.react-datepicker-wrapper {
}
.react-datepicker__input-container {
  display: block;
  padding-right: 20px;
}
.react-datepicker__input-container input {
  height: 40px;
  width: 100%;
  background: #fff;
  color: #000;
  border: none;
  padding: 0 10px;
}
